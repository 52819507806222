import React, { Component } from 'react'
import './UserBadge.css'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from '../../atom/icon'
import { userSelector } from '../../../ducks/users'

const mapStateToProps = (store) => {
  return {
    profile: userSelector(store, store.users.me),
  }
}

class UserBadge extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawer: false,
    };
  }

  render() {

    let pictureUrl

    let nrOfQuote = this.props.nrOfQuote
    let notifications = !!nrOfQuote;

    if (nrOfQuote > 0) {
      notifications = true;
    }

    if (this.props.profile && this.props.profile.picture) {
      pictureUrl = `https://images.loading.se/200x200/${this.props.profile.picture}`
    }

    return (
      <div className="Header-links">
        <NavLink className="Header-link" activeClassName="Header-link-active" to='/profil' exact={true}>
          <div className={'Header-profile-wrapper ' + (!pictureUrl ? 'default-avatar' : '')}>
            { pictureUrl ? <img className="Header-profile" alt="Profile" src={ pictureUrl } />
              : <Icon name="play-pause-bubble" color="white" background={'light-grey'} />
            }
            { notifications ? <div className="Header-notification">{ nrOfQuote }</div> : '' }
          </div>
        </NavLink>
      </div>  
    )
  }
}

export default connect(mapStateToProps)(UserBadge);
