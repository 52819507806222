import React, { Component } from 'react';
import PageListLoader from '../../components/organism/page-list/Loader';
import LoaderBox from '../../components/atom/LoaderBox';

export default class Cover extends Component {
  render() {
    let ListLoader = [];
    for (let i = 0; i < 15; i++) {
      ListLoader.push(<PageListLoader key={i} />);
    }

    return (
      <div className="Redaktionellt-page Page-list-width">
        <div className="Page-list-header">
          <h1>
            <LoaderBox width="30%" height="45px" />
          </h1>
        </div>
        <div className="Page-sorting">
          <div className="Redaktionen">
            <div className="Redaktionen-box">
              <div style={{ width: '65%' }}>
                <LoaderBox text="Här hittar du alla texter skrivna av redaktionen och du kan läsa mer om dem om du följer länken nedan." />
              </div>

              <div
                style={{
                  height: '30%',
                  width: '30%',
                  aspectRatio: 'auto 76 / 76',
                }}
              >
                <LoaderBox width="100%" height="100%" />
              </div>
              <h3>
                <LoaderBox text="Läs mer om redaktionen här" />
              </h3>
            </div>
          </div>
          <div className="Articles">
            <h3>
              <LoaderBox text="Artiklar" style={{ display: 'inline-block' }} />
            </h3>
            <LoaderBox
              width="80%"
              height="32px"
              style={{ margin: '10px 5px', borderRadius: '20px' }}
            />

            <h3>
              <LoaderBox text="Artiklar" style={{ display: 'inline-block' }} />
            </h3>
            <LoaderBox
              width="40%"
              height="32px"
              style={{ margin: '10px 5px', borderRadius: '20px' }}
            />
          </div>
        </div>
        <div className="Page-list">
          <div
            className="Page-list-paging"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <span style={{ marginTop: '-2px', width: '100px' }}>
              <LoaderBox width="100%" height="18px" />
            </span>
          </div>
          {ListLoader}
        </div>
      </div>
    );
  }
}
