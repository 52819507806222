export default function render(props) {
	const { type, color, width, height } = props;

	const style = {
		width: width + 'px',
		height: height + 'px',
	};

	return (
		<div
			className="Css-icon"
			data-color={color}
			data-type={type}
			role="img"
			alt={type + ' icon'}
			style={style}
		></div>
	);
}

// <CssIcon type="play" color="white" />
