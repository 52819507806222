import React, { Component } from 'react';
import LoaderBox from '../../components/atom/LoaderBox';

let profileStyle = {
  width: '100px',
  height: '100px',
  borderRadius: '8px',
  overflow: 'hidden',
};

if (window.innerWidth < 769) {
  profileStyle = {
    ...profileStyle,
    width: '40px',
    height: '40px',
  };
}

export default class Cover extends Component {
  render() {
    return (
      <div className="PostItem-container">
        <div className="PostItem-body">
          <div className="PostItem-user">
            <div className="PostItem-portrait-container" style={profileStyle}>
              <LoaderBox width="100%" height="100%" />
            </div>
            <div className="PostItem-username">
              <LoaderBox width="200px" height="20px" />
            </div>
          </div>
          <div className="PostItem-text">
            <div className="PostItem-regular">
              <LoaderBox width="100%" height="75px" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
