import React, { Component } from 'react';
import PageListLoader from '../../components/organism/page-list/Loader';
import './Home.css';
import '../../components/organism/list/List.css';
import '../../components/organism/wall/Wall.css';
import LoaderBox from '../../components/atom/LoaderBox';

export default class Cover extends Component {
  render() {
    let ListLoader = [];
    for (let i = 0; i < 15; i++) {
      ListLoader.push(<PageListLoader key={i} />);
    }

    const smallCover = (
      <div className="Cover-small">
        <div className="Cover-image">
          <LoaderBox width="100%" height="100%" />
        </div>
        <div className="Cover-content">
          <div className="tag" style={{ color: 'transparent' }}>
            Loading
          </div>
          <br />
          <LoaderBox width="80%" height="25px" />
          <LoaderBox width="60%" height="25px" />
          <span className="byline">
            <LoaderBox width="100px" height="25px" />
          </span>
        </div>
      </div>
    );

    return (
      <div>
        <div className={'Home-item-wall'}>
          <div className={'Wall-container'}>
            <div className="Cover">
              <LoaderBox
                width="100%"
                height="100%"
                style={{ position: 'absolute' }}
              />
              <div className="Cover-content">
                <div className="tag" style={{ color: 'transparent' }}>
                  Loading
                </div>
                <br />
                <div className="title">
                  <span style={{ color: 'transparent' }}>
                    Loading loading loading loading loading
                  </span>
                </div>
                <br />
                <span className="byline" style={{ color: 'transparent' }}>
                  Loading loading
                </span>
              </div>
            </div>
            {smallCover}
            {smallCover}
            {smallCover}
          </div>
        </div>

        <div className={'Home-item-list'}>
          <div className={'List-container'}>
            <div className="List-text" style={{ height: '36px' }}>
              <LoaderBox width="320px" height="32px" />
            </div>
            {ListLoader}
          </div>
        </div>
      </div>
    );
  }
}
