import React, { Component } from 'react';
import notFound from '../../assets/404.svg';

export default class NotFound extends Component {

  render() {
    return (
      <div>
        <img src={notFound} alt="404 Not Found" />
      </div>
    );
  }
}