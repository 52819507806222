import React, { Component } from 'react';
import LoaderBox from '../../atom/LoaderBox';

export default class Cover extends Component {
  render() {
    return (
      <div className={'Row-links-container'} style={{ height: '67px' }}>
        <a className="Row-forum-container" href="#" style={{ width: '60%', 'flexDirection': 'column', 'alignItems': 'inherit' }}>
            <LoaderBox width="100%" height="20px" />
            <LoaderBox width="50%" height="15px" style={{'marginTop': '5px'}}/>
        </a>

        <a className="Row-bubble-container" href="#" style={{ width: '40%' }}>
            <LoaderBox width="75px" height="20px" style={{'marginLeft': 'auto'}}/>
            <LoaderBox width="60%" height="15px" style={{'marginLeft': 'auto'}}/>
        </a>
      </div>
    );
  }
}
