import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CONFIG from './utils/config'

// Redux
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from './ducks'

const middleware = [
	thunk,
	CONFIG.DEBUG,
  ].filter(Boolean);
  const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

const store = createStoreWithMiddleware(
  reducers
)

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
