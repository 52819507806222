import React, { Component } from 'react';
import PageListLoader from '../../components/organism/page-list/Loader';
import LoaderBox from '../../components/atom/LoaderBox';

export default class Cover extends Component {
  render() {
    let ListLoader = [];
    for (let i = 0; i < 15; i++) {
      ListLoader.push(<PageListLoader key={i} />);
    }

    return (
      <div className="Games-page Page-list-width">
        <div className="Page-list-header">
          <h1>
            <LoaderBox width="30%" height="50px" />
          </h1>
          <div style={{ margin: '1em 0' }}>
            <LoaderBox
              width="100%"
              height="37px"
              text="Här pratar vi om allt som rör spel. Senaste nytt, skvaller, rykten, HYPE, kärleksförklaringar, åsikter och mer därtill. Låt konsolkriget vara förpassat till historien och visa respekt."
            />
          </div>
        </div>
        <div className="Page-list">
          <div
            className="Page-list-paging"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <span style={{ width: '150px' }}>
              <LoaderBox width="100%" height="18px" />
            </span>
          </div>
          {ListLoader}
        </div>
      </div>
    );
  }
}
