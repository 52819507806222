import get from 'lodash/get'
import map from 'lodash/map'
import assign from 'lodash/assign'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { GET_POSTS } from './posts'
import { GET_EVENTS } from './events'
import { POST_SEARCH } from './search'
import { LOGOUT } from './auth'
import CONFIG from '../utils/config'

// Actions
const GET_PROFILE = 'loading/users/GET_PROFILE';
const TEMPORARY_PICTURE = 'loading/users/TEMPORARY_PICTURE';
const SEND_RESET_TOKEN = 'loading/users/SEND_RESET_TOKEN';
const TOGGLE_USER_LOCK = 'loading/users/TOGGLE_USER_LOCK';
const GET_PATREON_CSRF_TOKEN = 'loading/users/GET_PATREON_CSRF_TOKEN';
const DELETE_PATREON = 'loading/users/DELETE_PATREON';

// Selectors

export function userSelector (store, id) {
  return get(store, ['users', 'list', id])
}

export function addUsersToPosts (store, posts) {
  const newPosts = posts.slice()
  map(newPosts, (post) => {
    const userObject = userSelector(store, post.userId)
    if (userObject) {
      post.user = userObject
    }
    const replyUserObject = userSelector(store, post.latestReplyUserId)
    if (replyUserObject) {
      post.latestReplyUser = replyUserObject
    }
  }, [])
  return newPosts
}

// Initial
const initialState = {
  me: null,
  list: {},
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case GET_PROFILE:
    case DELETE_PATREON:
      if (action.error) {
        return state
      }

      return {
        me: action.payload.user.id,
        list: assign({}, state.list, { [action.payload.user.id]: action.payload.user})
      }

    case GET_POSTS:
    case GET_EVENTS:
    case POST_SEARCH:
    case TOGGLE_USER_LOCK:
      if (action.error) {
        return state
      }
      // Filter out me cause it contains extended information
      const me = userSelector({ users: state }, state.me)
      const meId = get(me, 'id')
      const filteredUsers = filter(action.payload.users, (u) =>{ return u.id !== meId })
      // Build user object with keys
      const newUsers = reduce(filteredUsers, (acc, user) => {
        return assign({}, acc, { [user.id]: user })
      }, {})
      return assign({}, state, {
        list: assign({}, state.list, newUsers)
      })

    case TEMPORARY_PICTURE:
      if (action.error) {
        return state
      }
      // Filter out me to set the image on the right user
      const myUser = userSelector({ users: state }, state.me)
      // Add base64 picture
      const newUser = assign({}, myUser, { picture: action.payload.picture })
      return assign({}, state, {
        list: assign({}, state.list, { [myUser.id]: newUser })
      })

    case LOGOUT:
      if (action.error) {
        return state
      }
      return {
        me: null,
      }

    default: return state;
  }
}

// Action Creators
export function getProfile () {
  return (dispatch, getState) => {
    const oldSession = JSON.parse(localStorage.getItem('session'))

    const myHeaders = new Headers()
    if (oldSession) {
      myHeaders.append('Authorization', `Bearer ${get(oldSession, 'accessToken')}`)
      myHeaders.append('Refresh-Token', `${get(oldSession, 'refreshToken')}`)
    }
    
    return fetch(`${CONFIG.API_URI}/v1/users/profile`, { method: 'GET', headers: myHeaders, credentials: 'include' })
      .then(function(response) {
        if (response.status !== 200) {
          throw new Error(response.error)
        }
        return response.json()
      })
      .then(user => {
        localStorage.removeItem('session');
        dispatch({
          type: GET_PROFILE,
          payload: {
            user,
          },
        })
        return user
      })
      .catch(err => {
        localStorage.removeItem('session');
        throw err
      })
  }
}

export function sendResetToken (email) {
  return (dispatch, getState) => {

    var rawDetails = {
      'email': email,
    };

    let details = pickBy(rawDetails, identity);

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/users/sendResetToken`, { method: 'POST', body: formBody })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: SEND_RESET_TOKEN
      })
      return post
    })
    .catch(err => {
      return err
    })
  }
}

export function resetPassword (password, tokenId) {
  return (dispatch, getState) => {

    var rawDetails = {
      'password': password,
      'tokenId': tokenId
    };

    let details = pickBy(rawDetails, identity);

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/users/resetPassword`, { method: 'POST', body: formBody })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: SEND_RESET_TOKEN
      })
      return post
    })
    .catch(err => {
      throw err
    })
  }
}

export function setTemporaryPicture(base64) {
  return (dispatch, getState) => {
    dispatch({
      type: TEMPORARY_PICTURE,
      payload: {
        picture: base64,
      }
    })
  }
}

// TODO implement deleteUser action
export function deleteUser ({ userId, comment}) {
  if (comment === null || comment === '') {
    alert('Låsning avbruten, glöm inte att lämna en anledning')
    return () => {}
  }

  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'comment': comment,
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }
    return fetch(`${CONFIG.API_URI}/v1/users/${userId}/lock`, { method: 'POST', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: TOGGLE_USER_LOCK,
        payload: {
          users: [ post.user ],
        },
      })
      return post
    })
    .catch(err => {
      return err
    })

  }
}

export function getPatreonCsrfToken () {
  return (dispatch) => {
    return fetch(`${CONFIG.API_URI}/v1/users/patreon`, { method: 'GET', credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(response => {
      dispatch({
        type: GET_PATREON_CSRF_TOKEN,
      })
      return response.csrfToken
    })
    .catch(err => {
      return err
    })

  }
}

export function deletePatreon () {
  return (dispatch) => {
    return fetch(`${CONFIG.API_URI}/v1/users/patreon`, { method: 'DELETE', credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(response => {
      dispatch({
        type: DELETE_PATREON,
        payload: {
          user: response.user,
        },
      })
      return
    })
    .catch(err => {
      return err
    })

  }
}
