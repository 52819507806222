import filter from 'lodash/filter';
import get from 'lodash/get';
import assign from 'lodash/assign';
import pickBy from 'lodash/assign';
import find from 'lodash/find';
import map from 'lodash/map';
import identity from 'lodash/identity';
import CONFIG from '../utils/config'
import { addUsersToPosts } from './users';

// Actions
export const POST_SEARCH = 'loading/search/POST_SEARCH';
export const GET_LOCATION = 'loading/search/GET_LOCATION';

// Selectors
export function searchResultSelector (store) {
  const filterArray = get(store, ['search', 'sort'])

  const postList = get(store, ['posts', 'list'])

  const populatedList = map(filterArray, (f) => {
    const match = find(postList, (p) => p.id === f, null)
    return match
  })

  const filterList = filter(populatedList, Boolean)

  const postsWithUsers = addUsersToPosts(store, filterList)
  return postsWithUsers
}

// Initial
const initialState = {
  sort: [],
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case POST_SEARCH:
      return assign({}, state, {
        sort: action.payload.sort
      })

    default: return state;
  }
}

// Action Creators
export function postSearch (query) {

  var rawDetails = {
    'query': query,
  };

  let details = pickBy(rawDetails, identity);

  let formBody = new URLSearchParams()
  for (var property in details) {
    formBody.set(property, details[property])
  }

  return (dispatch, getState) => fetch(`${CONFIG.API_URI}/v1/search/`, { method: 'POST', body: formBody })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(res => {

      let postIds = [];
      map(res.posts, (p) => {
        postIds.push(p.id)
      })

      dispatch({
        type: POST_SEARCH,
        payload: {
          users: res.users,
          posts: res.posts,
          sort: postIds
        }
      })
      return res.page
    })
    .catch(err => {
      return err
    })
}

export function getLocation (postId) {
  return (dispatch, getState) => fetch(`${CONFIG.API_URI}/v1/search/location/${postId}`, { method: 'GET' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(res => {
      dispatch({
        type: GET_LOCATION,
      })
      return res.page
    })
    .catch(err => {
      return err
    })
}
