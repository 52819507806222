import CONFIG from '../utils/config'

// Actions


// Selectors

// Initial
const initialState = {
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {

    default: return state;
  }
}

// Action Creators
function uploadImage(acceptedFile, uploadUrl) {
  const xhr = new XMLHttpRequest();

  xhr.open('PUT', uploadUrl)
  xhr.upload.onprogress = (evt) => {
  };
  xhr.onload = function (v) {
  };
  xhr.onerror = function (e) {
  };
  xhr.send(acceptedFile);
}

export function postImage (acceptedFile, type, usageType, postId) {
  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'type': type,
      'usageType': usageType,
      'postId': postId,
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/image/`, { method: 'POST', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(res => {
      const uploadUrl = res.urls[0]
      uploadImage(acceptedFile, uploadUrl)
      return res
    })
    .catch(err => {
      return err
    })

  }
}
