import get from 'lodash/get';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import take from 'lodash/take';
import map from 'lodash/map';
import assign from 'lodash/assign';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import reduce from 'lodash/reduce';

import CONFIG from '../utils/config'
import { addUsersToPosts } from './users';
import { GET_EVENTS } from './events'
import { POST_SEARCH } from './search'

// Actions
export const GET_POSTS = 'loading/posts/GET_POSTS';
export const TOGGLE_POST_LOCK = 'loading/posts/TOGGLE_POST_LOCK';

// Selectors
export function singlePostSelector (store, postId) {
  const singlePost = get(store, ['posts', 'list', postId])
  const postsWithUsers = addUsersToPosts(store, [singlePost])
  return postsWithUsers[0]
}

export function postSelector (store, postId) {
  const originalPost = get(store, ['posts', 'list', postId])
  const postList = get(store, ['posts', 'list'])
  const filterList = filter(postList, (p) => p.parentId === postId)
  if (originalPost) {
    filterList.push(originalPost)
  }
  const postsWithUsers = addUsersToPosts(store, filterList)
  const sortedPostList = orderBy(postsWithUsers, ['createdAt'], ['asc'])
  return sortedPostList
}

export function postSelectorWithList (store, postId, page) {
  const pageFilter = `${postId}-${page ? page : 1}`

  const filterArray = get(store, ['posts', 'pages', [`${pageFilter}`]])

  const postList = get(store, ['posts', 'list'])

  const filterList = filter(postList, (p) => {
    const match = find(filterArray, (f) => p.id === f, null)
    if (match) return p
  })
  const postsWithUsers = addUsersToPosts(store, filterList)
  const sortedPostList = orderBy(postsWithUsers, ['createdAt'], ['asc'])
  return sortedPostList
}

export function postsSelector (store, category, page) {
  const postList = get(store, ['posts', 'list'])
  let categoryList = postList;
  if (category) {
    categoryList = filter(postList, (p) => p.category === category)
  }
  let filterList = filter(categoryList, (p) => !p.parentId)
  const postsWithUsers = addUsersToPosts(store, filterList)
  const sortedPostList = orderBy(postsWithUsers, ['updatedAt'], ['desc'])
  const takeEnough = take(sortedPostList, 15)
  return takeEnough
}

export function postsSelectorWithList (store, category, page) {
  const pageFilter = `${category}-${page ? page : 1}`

  const filterArray = get(store, ['posts', 'pages', [`${pageFilter}`]])

  const postList = get(store, ['posts', 'list'])

  const populatedList = map(filterArray, (f) => {
    const match = find(postList, (p) => p.id === f, null)
    return match
  })

  const filterList = filter(populatedList, Boolean)

  const postsWithUsers = addUsersToPosts(store, filterList)
  return postsWithUsers
}

export function editorialsSelector (store) {
  const postList = get(store, ['posts', 'list'])
  const filterList = filter(postList, (p) => (p.postType !== 'regular'))
  const sortedFourList = orderBy(filterList, ['publicationDate'], ['desc'])
  const fourList = filter(sortedFourList, (p, i) => (i < 4))
  const postsWithUsers = addUsersToPosts(store, fourList)
  const sortedPostList = orderBy(postsWithUsers, ['publicationDate'], ['desc'])
  return sortedPostList
}


// Initial
const initialState = {
  list: {},
  pages: {},
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case GET_POSTS:
    case GET_EVENTS:
    case TOGGLE_POST_LOCK:
    case POST_SEARCH:
      if (action.error) {
        return state
      }
      const newPosts = reduce(action.payload.posts, (acc, post) => {
        return assign({}, acc, { [post.id]: post })
      }, {})
      return assign({}, state, {
        list: assign({}, state.list, newPosts),
        pages: assign({}, state.pages, action.payload.pages),
      })

    default: return state;
  }
}

// Action Creators
export function getPosts (category, page, options) {
  return async (dispatch, getState) => {
    const state = getState()

    const myHeaders = new Headers()
    if (options) {
      if (options.type === 'all') {
        myHeaders.append('post-type', 'neRegular')
      } else {
        myHeaders.append('post-type', options.type)
      }
      if (options.sort === 'title') myHeaders.append('sort', 'title')
    }
    if (category) myHeaders.append(`${category}`, category)
    if (page) myHeaders.append('Page', page)
  
    return fetch(`${CONFIG.API_URI}/v1/posts/`, { method: 'GET', headers: myHeaders })
    .then(async function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(res => {
  
      let postIds = [];
      map(res.posts, (p) => {
        postIds.push(p.id)
      })    
      const savePage = { [`${category}-${page ? page : 1}`] : postIds }
      dispatch({
        type: GET_POSTS,
        payload: {
          posts: res.posts,
          users: res.users,
          events: res.events,
          pages: savePage,
        },
      })
      return res
    })
    .catch(err => {
      return err
    })

  }
}

export function getPost(postId, page) {
  const myHeaders = new Headers()
  if (page) myHeaders.append('Page', page)

  return (dispatch, getState) => fetch(`${CONFIG.API_URI}/v1/posts/${postId}`, { method: 'GET', headers: myHeaders })
  .then(function(response) {
    if (response.status !== 200) {
      throw new Error(response.error)
    }
    return response.json()
  })
  .then(res => {
    let postIds = [];
    map(res.posts, (p) => {
      postIds.push(p.id)
    })    
    const savePage = { [`${postId}-${page ? page : 1}`] : postIds }
    dispatch({
      type: GET_POSTS,
      payload: {
        posts: res.posts,
        users: res.users,
        events: res.events,
        pages: savePage,
      },
    })
    return res
  })
  .catch(err => {
    throw err
  })
}

export function createPost (title, body, category, postType, publicationDate, coverImage) {
  return (dispatch, getState) => {
    const state = getState()

    var rawDetails = {
      'title': title,
      'body': body,
      'category': category,
      'postType': postType,
      'publicationDate': publicationDate,
      'coverImage': coverImage,
    };

    let details = pickBy(rawDetails, identity);

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/posts/`, { method: 'POST', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 201) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: GET_POSTS,
        payload: {
          posts: [ post ],
        },
      })
      return post
    })
    .catch(err => {
      return err
    })

  }
}

export function deletePost ({ postId, comment }) {
  
  if (comment === null || comment === '') {
    alert('Borttagning avbruten, glöm inte att lämna en anledning')
    return () => {}
  }

  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'comment': comment,
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }
    return fetch(`${CONFIG.API_URI}/v1/posts/${postId}`, { method: 'DELETE', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      return post
    })
    .catch(err => {
      return err
    })

  }
};

export function addReply (postId, body) {
  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'body': body,
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/posts/${postId}`, { method: 'POST', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 201) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: GET_POSTS,
        payload: {
          posts: [ post ],
        },
      })
      return post
    })
    .catch(err => {
      return err
    })

  }
}

export function updatePost (postId, body) {
  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'body': body,
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/posts/${postId}`, { method: 'PATCH', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: GET_POSTS,
        payload: {
          posts: [ post ],
        },
      })
      return post
    })
    .catch(err => {
      return err
    })

  }
}

export function moderatePost ({ postId, title = null, category = null, postType = null, coverImage = null, comment}) {
  return (dispatch, getState) => {
    const state = getState()

    var details = {};

    if (title) details.title = title
    if (category) details.category = category
    if (postType) details.postType = postType
    if (coverImage) details.coverImage = coverImage

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/posts/${postId}`, { method: 'PATCH', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: GET_POSTS,
        payload: {
          posts: [ post ],
        },
      })
      return post
    })
    .catch(err => {
      return err
    })

  }
}

export function togglePostLock ({ postId, comment }) {
  if (comment === null || comment === '') {
    alert('Låsning avbruten, glöm inte att lämna en anledning')
    return () => {}
  }
  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'comment': comment,
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/posts/${postId}/lock`, { method: 'POST', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: TOGGLE_POST_LOCK,
        payload: {
          posts: [ post ],
        },
      })
      return post
    })
    .catch(err => {
      return err
    })

  }
}

export function togglePromotion ({ postId, coverImage }) {
  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'coverImage': coverImage,
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/posts/${postId}/promote`, { method: 'POST', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(post => {
      dispatch({
        type: GET_POSTS,
        payload: {
          posts: [ post ],
        },
      })
      return post
    })
    .catch(err => {
      return err
    })

  }
}
