import React, { Component } from 'react';
import './Footer.css';
import { Link, Route, Switch } from 'react-router-dom';
import Patreon from '../../../assets/Patreon';
import Twitch from '../../../assets/Twitch';
import Youtube from '../../../assets/Youtube';
import Facebook from '../../../assets/Facebook';
import Instagram from '../../../assets/Instagram';
import Twitter from '../../../assets/Twitter';
import BubblePoint from '../../../assets/BubblePoint';

export default class Cover extends Component {

  render() {
    return (
      <div className="Footer-container">
        <div className="Footer-social">
          <div className="Footer-social-group">
            <a href="https://twitter.com/loadingse" target="_blank" rel='noreferrer noopener' className="Footer-icon">
              <Twitter color={'#959595'} />
            </a>
            <a href="https://www.instagram.com/loading.se/" target="_blank" rel='noreferrer noopener' className="Footer-icon">
              <Instagram color={'#959595'} />
            </a>
            <a href="https://www.facebook.com/Loading-130612386998329/" target="_blank" rel='noreferrer noopener' className="Footer-icon">
              <Facebook color={'#959595'} />
            </a>
            <a href="https://www.youtube.com/channel/UCNNpD_076LggYcery93ZmSA" target="_blank" rel='noreferrer noopener' className="Footer-icon">
              <Youtube color={'#959595'} />
            </a>
            <a href="https://www.twitch.tv/loadingse" target="_blank" rel='noreferrer noopener' className="Footer-icon">
              <Twitch color={'#959595'} />
            </a>
          </div>
          <a href="https://www.patreon.com/loadingse" target="_blank" rel='noreferrer noopener' className="Footer-patreon">
              <Patreon className={'Patreon-image'} />
            <div className="Footer-bubble-container">
              <div className="Footer-bubble">
                Stöd oss på Patreon!
              </div>
              <BubblePoint className={'Patreon-bubble-point'} />
            </div>
          </a>
        </div>
        <div className="Footer-links">
          <div>
            <Link to="/sekretess" className="Footer-link">Sekretesspolicy</Link>
          </div>
          <div>
            <Link to="/cookies" className="Footer-link">Cookies</Link>
          </div>
          <div>
            <Link to="/kontakt" className="Footer-link">Kontakt</Link>
          </div>
          <div>
            &copy; Loading 2018–{new Date().getFullYear()}
          </div>
        </div>
        <Switch>
          <Route path='/post'>
            <div className="Footer-links">
              <a href="https://icons8.com" target="_blank" rel='noreferrer noopener' className="Footer-link">Ikoner från icons8.com</a>
            </div>
          </Route>
        </Switch>
        <Switch>
          <Route path='/registrera'>
          <div className="Footer-links">
            Den här sidan är skyddad av reCAPTCHA och Google's <a href="https://policies.google.com/privacy" rel='noreferrer noopener' className="Footer-captcha">Sekretesspolicy</a> och <a href="https://policies.google.com/terms" rel='noreferrer noopener' className="Footer-captcha">Användarvillkor</a> gäller.
          </div>
          </Route>
        </Switch>
      </div>
    );
  }
}
