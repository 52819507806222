import React, { Component } from 'react';
import LoaderBox from '../../components/atom/LoaderBox';

let headlineStyle = { width: '65%', height: '36px', overflow: 'hidden' };

if (window.innerWidth < 769) {
  headlineStyle = {
    ...headlineStyle,
    width: '100%',
    height: '81px',
  };
}

export default class Cover extends Component {
  render() {
    return (
      <div className="PostItem-header">
        <div style={headlineStyle}>
          <LoaderBox width="100%" height="100%" />
        </div>
        <div
          className="PostItem-header-category"
          style={{ marginTop: '7px', height: '26px', overflow: 'hidden' }}
        >
          <LoaderBox width="100px" height="100%" />
        </div>
        <div
          className="PostList-pagination"
          style={{
            width: '250px',
            height: '33px',
            overflow: 'hidden',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <LoaderBox width="100%" height="100%" />
        </div>
      </div>
    );
  }
}
