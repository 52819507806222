import React from 'react';
import { useLocation } from 'react-router-dom';

import HomeLoader from './pages/home/Loader'
import PageListLoader from './pages/games/Loader-2'
import PostLoader from './pages/post/LoaderWhole'
import RedaktionelltLoader from './pages/redaktionellt/Loader'
import AboutLoader from './pages/about/Loader'
import LoginLoader from './pages/login/Loader'

function Skeletons() {
  const location = useLocation()
  if (location.pathname === '/') return <HomeLoader />
  if (location.pathname.includes('/spel')) return <PageListLoader />
  if (location.pathname.includes('/annat')) return <PageListLoader />
  if (location.pathname.includes('/post')) return <PostLoader />
  if (location.pathname.includes('/redaktionellt')) return <RedaktionelltLoader />
  if (location.pathname.includes('/om')) return <AboutLoader />
  if (location.pathname.includes('/inloggning')) return <LoginLoader />
  return <div>Loading... </div>
}
  
export default Skeletons