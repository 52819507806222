import React, { Component } from 'react';
import LoaderBox from '../../components/atom/LoaderBox';

export default class Cover extends Component {
  render() {
    <LoaderBox
      style={{ margin: '0 auto', display: 'inline-block' }}
      text="Redaktionen"
    />;

    return (
      <div className="Login-container">
        <div className="Login-column Login-first">
          <div>
            <h1>
              <LoaderBox text="Logga in" />
            </h1>
            <LoaderBox text="Välkommen in tillbaka äventyrare. Glöm inte att ta en vända vid baren för att plocka upp ett par potions. Se också till att hålla dig ifrån trubbel i byn!" />
          </div>
          <LoaderBox
            width="190px"
            height="190px"
            style={{ aspectRatio: 'auto 1 / 1', marginLeft: '21px' }}
          />
        </div>

          <LoaderBox
            height="75px"
            style={{ borderRadius: '20px', marginBottom: '14px' }}
          />
          <LoaderBox
            height="75px"
            style={{ borderRadius: '20px' }}
          />
          <div className="Login-column" style={{paddingTop: '32px'}}>
            <LoaderBox
              width="133px"
              height="48px"
              style={{ borderRadius: '20px', marginRight: 'auto' }}
            />
            <LoaderBox
              width="140px"
              style={{ height: '19px', margin: 'auto 0 auto 16px' }}
            />
          </div>
      </div>
    );
  }
}
