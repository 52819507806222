import React, { Component } from 'react';
import './Icon.css';
import playWhiteOnPink from '../../../assets/icons/play_pause_stop-01.svg'
import playWhiteOnDarkpink from '../../../assets/icons/play_pause_stop-01_darkpink.svg'
import pauseWhiteOnPink from '../../../assets/icons/play_pause_stop-02.svg'
import pauseWhiteOnDarkpink from '../../../assets/icons/play_pause_stop-02_darkpink.svg'
import stopWhiteOnPink from '../../../assets/icons/play_pause_stop-03.svg'
import stopWhiteOnDarkpink from '../../../assets/icons/play_pause_stop-03_darkpink.svg'
import playPinkOnWhite from '../../../assets/icons/play_pause_stop-04.svg'
import pausePinkOnWhite from '../../../assets/icons/play_pause_stop-05.svg'
import stopPinkOnWhite from '../../../assets/icons/play_pause_stop-06.svg'
import playPinkOnDarkGrey from '../../../assets/icons/play_pause_stop-07.svg'
import pausePinkOnDarkGrey from '../../../assets/icons/play_pause_stop-08.svg'
import stopPinkOnDarkGrey from '../../../assets/icons/play_pause_stop-09.svg'
import playPinkOnLightGrey from '../../../assets/icons/play_pause_stop-10.svg'
import pausePinkOnLightGrey from '../../../assets/icons/play_pause_stop-11.svg'
import stopPinkOnLightGrey from '../../../assets/icons/play_pause_stop-12.svg'
import playWhiteOnDarkGrey from '../../../assets/icons/play_pause_stop-13.svg'
import pauseWhiteOnDarkGrey from '../../../assets/icons/play_pause_stop-14.svg'
import stopWhiteOnDarkGrey from '../../../assets/icons/play_pause_stop-15.svg'
import playWhiteOnLightGrey from '../../../assets/icons/play_pause_stop-16.svg'
import pauseWhiteOnLightGrey from '../../../assets/icons/play_pause_stop-17.svg'
import stopWhiteOnLightGrey from '../../../assets/icons/play_pause_stop-18.svg'
import playWhite from '../../../assets/icons/play_pause_stop-19.svg'
import pauseWhite from '../../../assets/icons/play_pause_stop-20.svg'
import stopWhite from '../../../assets/icons/play_pause_stop-21.svg'
import playPink from '../../../assets/icons/play_pause_stop-22.svg'
import pausePink from '../../../assets/icons/play_pause_stop-23.svg'
import stopPink from '../../../assets/icons/play_pause_stop-24.svg'
import playDarkGrey from '../../../assets/icons/play_pause_stop-25.svg'
import pauseDarkGrey from '../../../assets/icons/play_pause_stop-26.svg'
import stopDarkGrey from '../../../assets/icons/play_pause_stop-27.svg'
import playLightGrey from '../../../assets/icons/play_pause_stop-28.svg'
import pauseLightGrey from '../../../assets/icons/play_pause_stop-29.svg'
import stopLightGrey from '../../../assets/icons/play_pause_stop-30.svg'
import playBubbleWhiteOnPink from '../../../assets/icons/pratbubbla-01.svg'
import playPauseBubbleWhiteOnPink from '../../../assets/icons/pratbubbla-02.svg'
import emptyBubblePink from '../../../assets/icons/pratbubbla-03.svg'
import emptyBubbleDarkpink from '../../../assets/icons/pratbubbla-03_darkpink.svg'
import playBubbleWhiteOnDarkGrey from '../../../assets/icons/pratbubbla-04.svg'
import playPauseBubbleWhiteOnDarkGrey from '../../../assets/icons/pratbubbla-05.svg'
import emptyBubbleDarkGrey from '../../../assets/icons/pratbubbla-06.svg'
import playBubbleWhiteOnLightGrey from '../../../assets/icons/pratbubbla-07.svg'
import playPauseBubbleWhiteOnLightGrey from '../../../assets/icons/pratbubbla-08.svg'
import emptyBubbleLightGrey from '../../../assets/icons/pratbubbla-09.svg'
import playBubblePinkOnDarkGrey from '../../../assets/icons/pratbubbla-10.svg'
import playPauseBubblePinkOnDarkGrey from '../../../assets/icons/pratbubbla-11.svg'
import playBubblePinkOnLightGrey from '../../../assets/icons/pratbubbla-12.svg'
import playPauseBubblePinkOnLightGrey from '../../../assets/icons/pratbubbla-13.svg'

export default class Icon extends Component {

  selectPlayIcon() {
    const { color, background } = this.props;

    switch(color) {
      case 'white':
        if (background === 'pink') { return playWhiteOnPink }
        else if (background === 'dark-grey') { return playWhiteOnDarkGrey }
        else if (background === 'light-grey') { return playWhiteOnLightGrey }
        else { return playWhite }
      case 'pink':
        if (background === 'white') { return playPinkOnWhite }
        else if (background === 'dark-grey') { return playPinkOnDarkGrey }
        else if (background === 'light-grey') { return playPinkOnLightGrey }
        else { return playPink }
      case 'dark-grey':
        return playDarkGrey
      case 'light-grey':
        return playLightGrey
      case 'dark-pink':
        return playWhiteOnDarkpink
      default:
        return playPink;
    }
  }

  selectPauseIcon() {
    const { color, background } = this.props;

    switch(color) {
      case 'white':
        if (background === 'pink') { return pauseWhiteOnPink }
        else if (background === 'dark-grey') { return pauseWhiteOnDarkGrey }
        else if (background === 'light-grey') { return pauseWhiteOnLightGrey }
        else { return pauseWhite }
      case 'pink':
        if (background === 'white') { return pausePinkOnWhite }
        else if (background === 'dark-grey') { return pausePinkOnDarkGrey }
        else if (background === 'light-grey') { return pausePinkOnLightGrey }
        else { return pausePink }
      case 'dark-grey':
        return pauseDarkGrey
      case 'light-grey':
        return pauseLightGrey
      case 'dark-pink':
        return pauseWhiteOnDarkpink
      default:
        return pausePink;
    }
  }

  selectStopIcon() {
    const { color, background } = this.props;

    switch(color) {
      case 'white':
        if (background === 'pink') { return stopWhiteOnPink }
        else if (background === 'dark-grey') { return stopWhiteOnDarkGrey }
        else if (background === 'light-grey') { return stopWhiteOnLightGrey }
        else { return stopWhite }
      case 'pink':
        if (background === 'white') { return stopPinkOnWhite }
        else if (background === 'dark-grey') { return stopPinkOnDarkGrey }
        else if (background === 'light-grey') { return stopPinkOnLightGrey }
        else { return stopPink }
      case 'dark-grey':
        return stopDarkGrey
      case 'light-grey':
        return stopLightGrey
      case 'dark-pink':
        return stopWhiteOnDarkpink
      default:
        return stopPink;
    }
  }

  selectPlayBubbleIcon() {
    const { color, background } = this.props;

    switch(color) {
      case 'white':
        if (background === 'pink') { return playBubbleWhiteOnPink }
        else if (background === 'dark-grey') { return playBubbleWhiteOnDarkGrey }
        else if (background === 'light-grey') { return playBubbleWhiteOnLightGrey }
        else { return playBubbleWhiteOnPink }
      case 'pink':
        if (background === 'dark-grey') { return playBubblePinkOnDarkGrey }
        else if (background === 'light-grey') { return playBubblePinkOnLightGrey }
        else { return playBubblePinkOnDarkGrey }
      default:
        return playBubbleWhiteOnPink;
    }
  }

  selectPlayPauseBubbleIcon() {
    const { color, background } = this.props;

    switch(color) {
      case 'white':
        if (background === 'pink') { return playPauseBubbleWhiteOnPink }
        else if (background === 'dark-grey') { return playPauseBubbleWhiteOnDarkGrey }
        else if (background === 'light-grey') { return playPauseBubbleWhiteOnLightGrey }
        else { return playPauseBubbleWhiteOnPink }
      case 'pink':
        if (background === 'dark-grey') { return playPauseBubblePinkOnDarkGrey }
        else if (background === 'light-grey') { return playPauseBubblePinkOnLightGrey }
        else { return playPauseBubblePinkOnDarkGrey }
      default:
        return playPauseBubbleWhiteOnPink;
    }
  }

  selectEmptyBubbleIcon() {
    const { color } = this.props;

    switch(color) {
      case 'pink':
        return emptyBubblePink
      case 'dark-grey':
        return emptyBubbleDarkGrey
      case 'light-grey':
        return emptyBubbleLightGrey
      case 'dark-pink':
        return emptyBubbleDarkpink
      default:
        return emptyBubblePink;
    }
  }

  render() {
    const { name, width, height } = this.props;
    let selectedIcon;

    switch(name) {
      case 'play':
        selectedIcon = this.selectPlayIcon();
        break;
      case 'pause':
        selectedIcon = this.selectPauseIcon();
        break;
      case 'stop':
        selectedIcon = this.selectStopIcon();
        break;
      case 'play-bubble':
        selectedIcon = this.selectPlayBubbleIcon();
        break;
      case 'play-pause-bubble':
        selectedIcon = this.selectPlayPauseBubbleIcon();
        break;
      case 'empty-bubble':
        selectedIcon = this.selectEmptyBubbleIcon();
        break;
      default:
        selectedIcon = playPink;
    }

    return (
      <img src={selectedIcon} className="Icon" alt={`${name ? name : 'play'}-icon`} style={{width: width+'px', height: height+'px'}} />
    );
  }
}