import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Logo from '../../atom/logo'
import CssIcon from '../../atom/CssIcon'
import UserBadge from '../../molecule/UserBadge'
import hamburger from '../../../assets/icons/hamburger.svg'
import { unreadEventSelector } from '../../../ducks/events';
import { searchResultSelector, postSearch, getLocation } from '../../../ducks/search';
import LoaderSearch from './Loader-search'

const mapStateToProps = (store) => {
  const unreadEvents = unreadEventSelector(store)
  return {
    numberOfEvents: unreadEvents && unreadEvents[0] && unreadEvents.length,
    search: searchResultSelector(store),
  }
}

class Header extends Component {

  constructor(props) {
    super(props);

    this.textInput = React.createRef();

    this.events = {
      searchClick: this._searchClick.bind(this),
      searchChange: this._searchChange.bind(this),
    }

    this.state = {
      drawer: false,
      viewSearch: false,
      searchTerm: '',
      searchResults: [],
      searchTimer: null,
      waiting: false,
      noResults: false
    };
  }

  _searchClick() {
    this.setState({ viewSearch: !this.state.viewSearch });
    if (!this.state.viewSearch) this.textInput.current.focus();
  }

  _setSearch(searchValue) {
    this.setState({ waiting: true })
    if (this.state.searchTimer) {
      clearInterval(this.state.searchTimer)
    }
    const searchTimeout = setTimeout(() => {
      this.props.dispatch(postSearch(searchValue))
      .then(() => {
        this.setState({
          waiting: false,
          searchResults: this.props.search
        })
        if (this.props.search.length <= 0) {
          this.setState({ noResults: true });
        }
      })
    }, 1000)
    this.setState({
      searchTimer: searchTimeout
    })
  }

  _searchChange(event) {
    const searchValue = event.target.value;
    this.setState({
      searchTerm: searchValue,
      searchResults: [],
      noResults: false
    });

    if (searchValue) {
      this._setSearch(searchValue);
    }
  }

  postClick(e, post) {
    if (post.parentId) {
      e.preventDefault()

      const postId = post.id
      this.props.dispatch(getLocation(postId)).then((page)=> {
        const pageNr = page === 0 ? '' : page;
        this.props.history.push(`/post/${post.parentId}/${pageNr}#post-${postId.slice(postId.length - 5)}`)
      })
    }
    this.setState({ viewSearch: !this.state.viewSearch })
  }

  renderPosts(posts) {
    let proccess = [];
    posts.forEach((each) => {
      proccess.push(this.renderPost(each));
    });
    return proccess;
  }

  renderPost(post) {
    const { searchTerm } = this.state;

    let text;
    const link = post.title ? '/post/' + post.id : '';
    const image = post.user.picture
      ? 'https://images.loading.se/200x200/' + post.user.picture
      : 'https://loading.se/static/media/pratbubbla-08.06a002e1.svg';
    const textStartIndex = post.body.toLowerCase().indexOf(searchTerm.toLowerCase());

    if (post.title) {
      text = post.title.slice(0, 150);
    } else {
      text = post.body;
    }

    const reg = new RegExp(searchTerm, 'gi');
    text = text.replace(reg, '<span class="highlight">$&</span>');

    // if (!~textStartIndex) {
    //   text = post.body.slice(0, 150); 
    // }

    return (
      <Link to={link} key={post.id} onClick={(e) => this.postClick(e, post)}>
        <div className="name">
        <img src={image} width="33" height="33"/>
          {post.user.name}
        </div>
        <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
      </Link>
    );
  }

  render() {
    const { loading, loggedIn } = this.props;
    const { drawer, viewSearch, searchResults, searchTerm, waiting, noResults } = this.state;

    const hasResults = searchResults.length != 0;
    let searchPosts;
    if (hasResults) searchPosts = this.renderPosts(searchResults);

    let iconColor = 'white';
    if (window.innerWidth < 769) iconColor = 'pink';

    const placeholderText = [
      'Sök',
      'Hur låter Super Mario någonsin?',
      'Finns klotet på riktigt?',
      'Spiderman',
      'Årets spel',
      'Hur många laxar i en laxask',
      'Vem är Oskar Skog?',
    ];    
    const randomPlaceholder = placeholderText[Math.floor(Math.random() * placeholderText.length)];

    let nrOfQuote = this.props.numberOfEvents ? this.props.numberOfEvents : 0;
    let notifications = !!nrOfQuote;

    return (
      <header className={this.state.viewSearch ? 'Header Header-active' : 'Header'}>
        <div className="Header-logo">
          <Link to="/">
            <Logo color="white" />
          </Link>
        </div>
        <div className="Header-search" onClick={ (element, next) => this.events.searchClick()}>
          <CssIcon type="ghost-search" color="white" width="34" height="34" />
          <CssIcon type="search" color="white" width="34" height="34" />
        </div>
        <nav className="Header-nav">
          <div className="Header-icons">
            <NavLink className="Header-icon-link" activeClassName="Header-icon-link-active" to='/spel' exact={true}>
              <CssIcon type="play" color="white" />
              <div className="Header-icon-text">Spel</div>
            </NavLink>
            <NavLink className="Header-icon-link" activeClassName="Header-icon-link-active" to='/annat' exact={true}>
              <CssIcon type="pause" color="white" />
              <div className="Header-icon-text">Annat</div>
            </NavLink>
            <NavLink className="Header-icon-link" activeClassName="Header-icon-link-active" to='/redaktionellt' exact={true}>
              <CssIcon type="stop" color="white" />
              <div className="Header-icon-text">Texter</div>
            </NavLink>
          </div>
          { loading &&
            <div className="Header-link" style={{ color: 'transparent'}}>
             Min sida
            </div>
          }
          { !loading && !loggedIn &&
            <div className="Header-links">
              <NavLink className="Header-link" activeClassName="Header-link-active" to='/inloggning' exact={true}>Logga in</NavLink>
              <NavLink className="Header-link" activeClassName="Header-link-active" to='/registrera' exact={true}>Registrera</NavLink>
            </div>  
          }
          { !loading && loggedIn &&
            <UserBadge nrOfQuote={nrOfQuote}/>
          }
        </nav>
        <img src={hamburger} className="Header-hamburger" alt="Toggle navigation menu" onClick={() => this.setState({ drawer: !this.state.drawer})} />
        { notifications ? <div className="Header-burger-notification">{ nrOfQuote }</div> : '' }
        { drawer &&
          <div className="Header-overlay" onClick={() => this.setState({ drawer: !this.state.drawer})}>
          </div>
        }
        <div className={this.state.viewSearch ? 'Header-search-box Header-search-active' : 'Header-search-box'}>
          <input type="text" maxLength="50" ref={this.textInput} placeholder={randomPlaceholder} onChange={this.events.searchChange} value={this.state.searchTerm} />
          <div className="Search-close" onClick={ (element, next) => this.events.searchClick()}></div>
        </div>
        {this.state.viewSearch &&
        <div className="Search-results" onClick={ (element, next) => this.events.searchClick()}>
          { waiting &&
            <div className="Search-posts">
              {[...Array(10)].map((e, i) => <LoaderSearch key={i} />)}
            </div>
          }
          { noResults &&
            <div className="Search-loader">Absolut nada, pröva något annat</div>
          }
          { hasResults &&
          <div className="Search-posts">
            { searchPosts }
          </div>
          }
        </div>
      }
        <div className={this.state.drawer ? 'Header-drawer-active' : 'Header-drawer'}>
          <NavLink onClick={() => this.setState({ drawer: !this.state.drawer})} className="Header-drawer-link" activeClassName="Header-drawer-link-active" to='/spel' exact={true}>
            <div className="Header-drawer-icon">
              <CssIcon type="play" color="pink" />
            </div>
            <div>Spel</div>
          </NavLink>
          <NavLink onClick={() => this.setState({ drawer: !this.state.drawer})} className="Header-drawer-link" activeClassName="Header-drawer-link-active" to='/annat' exact={true}>
            <div className="Header-drawer-icon">
              <CssIcon type="pause" color="pink" />
            </div>
            <div>Annat</div>
          </NavLink>
          <NavLink onClick={() => this.setState({ drawer: !this.state.drawer})} className="Header-drawer-link" activeClassName="Header-drawer-link-active" to='/redaktionellt' exact={true}>
            <div className="Header-drawer-icon">
              <CssIcon type="stop" color="pink" />
            </div>
            <div>Texter</div>
          </NavLink>
          { !loggedIn &&
            <div className="Header-drawer-section">
              <hr />
              <NavLink onClick={() => this.setState({ drawer: !this.state.drawer})} className="Header-drawer-link" activeClassName="Header-drawer-link-active" to='/inloggning' exact={true}>
                <div className="Header-drawer-text">Logga in</div>
              </NavLink>
              <NavLink onClick={() => this.setState({ drawer: !this.state.drawer})} className="Header-drawer-link" activeClassName="Header-drawer-link-active" to='/registrera' exact={true}>
                <div className="Header-drawer-text">Registrera</div>
              </NavLink>
            </div>
          }
          { loggedIn &&
            <div className="Header-drawer-section">
              <hr />
              <NavLink onClick={() => this.setState({ drawer: !this.state.drawer})} className="Header-drawer-link" activeClassName="Header-drawer-link-active" to='/profil' exact={true}>
                <div className="Header-drawer-text">Min sida { notifications ?  '- ' + nrOfQuote  : '' } </div>
              </NavLink>
            </div>
          }
        </div>
      </header>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Header);
