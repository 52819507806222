import { combineReducers } from 'redux'

import auth from './auth'
import users from './users'
import posts from './posts'
import upload from './upload'
import events from './events'
import search from './search'

export default combineReducers({
  auth,
  users,
  posts,
  upload,
  events,
  search,
})
