import React, { Component } from 'react';
import TopLoader from './LoaderTop';
import PostLoader from './Loader';

let style;
if (window.innerWidth > 768) {
  style = {
    marginBottom: '23px',
  };
}

export default class Cover extends Component {
  render() {
    return (
      <div className="Post-container">
        <div style={style}>
          <TopLoader />
        </div>
        <PostLoader />
        <PostLoader />
        <PostLoader />
        <PostLoader />
        <PostLoader />
        <PostLoader />
      </div>
    );
  }
}
