export default function render(props) {
	const { text, width, height, style } = props;

	const theStyle = {
		width: width,
		'minHeight': height,
		...style
	};

	return (
		<div className="Loader-box" style={theStyle}>
			{text}
		</div>
	);
}
