import React, { Component, Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'; // Link behövs för top banner
import { connect } from 'react-redux';
import { getProfile } from './ducks/users'
import { getEvents } from './ducks/events';
import Header from './components/organism/header'
import get from 'lodash/get'
import Footer from './components/organism/footer'
import NotFound from './pages/notfound'
import Darkmode from './utils/darkmode';
import Skeletons from './Skeletons'

if (Darkmode()) {
	document.querySelector('body').classList.toggle('darkmode');
}

const Home = lazy(()=> import('./pages/home'))
const Signup = lazy(()=> import('./pages/signup'))
const Profile = lazy(()=> import('./pages/profile'))
const Games = lazy(()=> import('./pages/games'))
const Other = lazy(()=> import('./pages/other'))
const Post = lazy(()=> import('./pages/post'))
const About = lazy(()=> import('./pages/about'))
const Redaktionellt = lazy(()=> import('./pages/redaktionellt'))
const Login = lazy(()=> import('./pages/login'))
const Privacy = lazy(()=> import('./pages/privacy'))
const Cookies = lazy(()=> import('./pages/cookies'))
const Contact = lazy(()=> import('./pages/contact'))
const Reset = lazy(()=> import('./pages/reset'))


const mapStateToProps = (store) => {
  return {
    token: store.auth.token,
    me: store.users.me,
  }
}

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      checkSession: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getProfile())
    .then(() => {
      this.setState({ checkSession: true})
      dispatch(getEvents())
    })
    .catch(() => {
      this.setState({ checkSession: true})
    })

    setInterval(this._fetchEvents.bind(this), 60000)
  }

  _fetchEvents() {
    const { dispatch, me } = this.props
    if (!me) return
    dispatch(getEvents())
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Header loading={!this.state.checkSession} loggedIn={this.props.me} />
          { /* Loadingkalendern är i full gång och nya luckor öppnas varje dag! */ }
          {/* Årets Loadingkalender är öppen för anmälan! */}
          {/* Loadingbössan 2021 – Loading stödjer Musikhjälpen! */}
          {/* <Link to="/post/637a6f401260e236980d0d0c" className="custom-loadingkalendern-note">Årets Loadingkalender är öppen för anmälan!</Link> */}
          <Suspense fallback={<Skeletons />}>
            <Switch>
              <Route
                key={1}
                path={"/"}
                exact={true}
                component={Home}
              />
              <Route
                key={2}
                path={"/spel"}
                exact={true}
                component={Games}
              />
              <Route
                key={3}
                path={"/spel/:page"}
                exact={true}
                component={Games}
              />
              <Route
                key={4}
                path={"/annat"}
                exact={true}
                component={Other}
              />
              <Route
                key={5}
                path={"/annat/:page"}
                exact={true}
                component={Other}
              />
              <Route
                key={6}
                path={"/registrera"}
                component={Signup}
              />
              <Route
                key={7}
                path={"/inloggning"}
                component={Login}
              />
              <Route
                key={8}
                path={"/profil"}
                component={Profile}
              />
              <Route
                key={9}
                path={"/post/:postId"}
                exact={true}
                component={Post}
              />
              <Route
                key={10}
                path={"/post/:postId/:page"}
                exact={true}
                component={Post}
              />
              <Route
                key={11}
                path={"/reset"}
                exact={true}
                component={Reset}
              />
              <Route
                key={12}
                path={"/reset/:tokenId"}
                exact={true}
                component={Reset}
              />
              <Route
                key={13}
                path={"/om"}
                component={About}
              />
              <Route
                key={14}
                path={"/redaktionellt"}
                exact={true}
                component={Redaktionellt}
              />
              <Route
                key={15}
                path={"/redaktionellt/:page"}
                exact={true}
                component={Redaktionellt}
              />
              <Route
                key={16}
                path={"/sekretess"}
                component={Privacy}
              />
              <Route
                key={17}
                path={"/kontakt"}
                component={Contact}
              />
              <Route
                key={18}
                path={"/cookies"}
                component={Cookies}
              />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
          <div className="Home-item-footer">
              <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default connect(mapStateToProps)(App);