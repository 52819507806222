import React, { Component } from 'react';
import LoaderBox from '../../atom/LoaderBox';

const post = (
  <a href="/">
    <div className="name">
      <LoaderBox
        width="33px"
        height="33px"
        style={{ marginRight: '11px', borderRadius: '8px' }}
      />
      <LoaderBox width="75px" height="20px" />
    </div>
    <div className="text">
      <LoaderBox width="100%" height="20px" />
    </div>
  </a>
);

export default class Cover extends Component {
  render() {
    return post;
  }
}
