import React from "react";

function BubblePoint({className}) {
  return (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.852 18.898'>
      <path fill='#ff424d' d='M0 0v18.9L18.852 0z'></path>
    </svg>
  );
}

export default BubblePoint;
