import React from "react";

function Patreon({className}) {
  return (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 799.69 767.71'>
      <g fill='#ff424d' fillRule='evenodd'>
        <path
          d='M3844.9 5757.8c-1190.8 0-2159.5-969.65-2159.5-2161.6 0-1188.3 968.78-2155.1 2159.5-2155.1 1187.1 0 2152.8 966.79 2152.8 2155.1 0 1191.9-965.74 2161.6-2152.8 2161.6M0 0h1054.41v5757.81H0V0'
          transform='matrix(1.3333 0 0 -1.3333 0 767.71) scale(.1)'
        ></path>
      </g>
    </svg>
  );
}

export default Patreon;