import React, { Component } from 'react';
import './Logo.css';
import logoBlack from '../../../assets/logotyp-01.svg'
import logoWhite from '../../../assets/logotyp-02.svg'
import logoPink from '../../../assets/logotyp-03.svg'
import logoDarkGrey from '../../../assets/logotyp-04.svg'
import logoLightGrey from '../../../assets/logotyp-05.svg'

export default class Header extends Component {

  render() {
    const { color } = this.props;
    let logo;

    switch(color) {
      case 'white':
          logo = logoWhite;
          break;
      case 'pink':
          logo = logoPink;
          break;
      case 'dark-grey':
          logo = logoDarkGrey;
          break;
      case 'light-grey':
          logo = logoLightGrey;
          break;
      default:
          logo = logoBlack;
    }

    return (
      <img src={logo} className="Logo" alt="logo" />
    );
  }
}