import assign from 'lodash/assign';
import get from 'lodash/get';
import CONFIG from '../utils/config'

// Actions
const LOGIN = 'loading/auth/LOGIN';
export const LOGOUT = 'loading/auth/LOGOUT';

const initialState = {
  loggedIn: false,
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case LOGIN:
      if (action.error) {
        return state
      }
      return {
        loggedIn: true,
      }
    
    case LOGOUT:
      if (action.error) {
        return state
      }
      return {
        loggedIn: false,
      }

    default: return state;
  }
}

// Action Creators
export function register (name, email, password, captcha) {

  var details = {
    'name': name,
    'email': email,
    'password': password,
    'g-recaptcha-response': captcha,
  };

  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  
  let formBody = new URLSearchParams()

  for (var property in details) {
    formBody.set(property, details[property])
  }

  return dispatch => fetch(`${CONFIG.API_URI}/v1/auth/register`, { method: 'POST', headers: myHeaders, body: formBody, credentials: 'include' })
    .then(function(response) {

      return response.json()
    })
    .then(res => {
      if (res.code === 400) {
        throw res
      }
      if (!res.user) {
        throw res
      }
      dispatch({
        type: LOGIN,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: LOGIN,
        payload: {
          status: 400
        },
      })
      if(/Validation error/i.test(err.message)) throw err.errors
      throw new Error(err.message)
    })
}

export function login (email, password) {

  var details = {
    'email': email,
    'password': password,
  };

  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  
  let formBody = new URLSearchParams()

  for (var property in details) {
    formBody.set(property, details[property])
  }

  return dispatch => fetch(`${CONFIG.API_URI}/v1/auth/login`, { method: 'POST', headers: myHeaders, body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(res => {
      dispatch({
        type: LOGIN,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: LOGIN,
        payload: {
          status: 400
        },
      })
      return Promise.reject(err)
    })
}

export function logout() {
  return (dispatch, getState) => fetch(`${CONFIG.API_URI}/v1/auth/logout`, { method: 'POST', credentials: 'include' })
  .then(res => {
    localStorage.removeItem('session');
    dispatch({
      type: LOGOUT,
    })
  })
  .catch(err => {
    localStorage.removeItem('session');
    dispatch({
      type: LOGOUT,
    })
  })

}