import React, { Component } from 'react';
import LoaderBox from '../../components/atom/LoaderBox';

export default class Cover extends Component {
  render() {
    let ListLoader = [];
    for (let i = 0; i < 10; i++) {
      ListLoader.push(
        <div className="About-people" key={i}>
          <div className="About-image">
            <LoaderBox width="150px" height="150px" />
          </div>
          <div className="About-content">
            <h3>
              <LoaderBox width="200px" text="#" />
            </h3>
            <h4>
              <LoaderBox width="80px" text="#" />
            </h4>
            <LoaderBox
              text="Jag är en fumlig och smått introvert smålänning som nu har bytt
                ut östkusten mot västkusten. Jag fann mitt intresse för spel på
                den där gamla goda tiden då man fick vänta på att internet
                skulle koppla upp sig. Jag har alltid haft en odödlig förkärlek
                för olika simulatorer, men letar ständigt efter nya unika och
                iögonfallande spel. Helst såna som är så där vackra att det gör
                lite ont i själen. Typ som Journey. Jag mår som bäst när jag får
                vara barfota i en gräsmatta ute på landet."
              style={{ margin: '16px 0' }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="About">
        <h1>
          <LoaderBox
            style={{ margin: '0 auto', display: 'inline-block' }}
            text="Redaktionen"
          />
        </h1>
        <div className="About-text">{ListLoader}</div>
      </div>
    );
  }
}
