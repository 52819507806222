import filter from 'lodash/filter';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';
import CONFIG from '../utils/config'
import { GET_POSTS } from './posts'

// Actions
export const GET_EVENTS = 'loading/events/GET_EVENTS';

// Selectors
export function eventSelector (store, id) {
  const eventList = get(store, ['events', 'list'])
  return filter(eventList, (event) => {
    const match = event.initialPost === id
    return match
  })
}

export function firstUnreadEventByPostSelector (store, id) {
  const eventList = get(store, ['events', 'list'])
  const eventsByPost = filter(eventList, (event) => {
    const match = event.initialPost === id
    return match
  })
  const unreadEvents = filter(eventsByPost, (event) => {
    const match = event.status === 'UNREAD'
    return match
  }, null)
  // Should sort these by time
  return unreadEvents ? unreadEvents[0] : null
}

export function unreadEventSelector (store) {
  const eventList = get(store, ['events', 'list'])
  return filter(eventList, (event) => {
    const match = event.status === 'UNREAD'
    return match
  })
}


// Initial
const initialState = {
  list: {},
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_POSTS:
    case GET_EVENTS:
      const events = action && action.payload && action.payload.events
      if (!events) return state
      const newEvents = reduce(events, (acc, event) => {
        return assign({}, acc, { [event._id]: event })
      }, {})
      return assign({}, state, {
        list: assign({}, state.list, newEvents)
      })

    default: return state;
  }
}

// Action Creators
export function getEvents () {
  return (dispatch, getState) =>  fetch(`${CONFIG.API_URI}/v1/events/`, { method: 'GET', credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(res => {
      dispatch({
        type: GET_EVENTS,
        payload: {
          events: res.events,
          users: res.users,
          posts: res.posts
        }
      })
      return res
    })
    .catch(err => {
      return err
    })
}

export function markRead (eventId) {
  return (dispatch, getState) => {
    const state = getState()

    var details = {
      'status': 'READ',
    };

    let formBody = new URLSearchParams()
    for (var property in details) {
      formBody.set(property, details[property])
    }

    return fetch(`${CONFIG.API_URI}/v1/events/${eventId}`, { method: 'PATCH', body: formBody, credentials: 'include' })
    .then(function(response) {
      if (response.status !== 200) {
        throw new Error(response.error)
      }
      return response.json()
    })
    .then(eventRes => {
      dispatch({
        type: GET_EVENTS,
        payload: {
          events: [ eventRes.event ]
        }
      })
      return eventRes
    })
    .catch(err => {
      return err
    })

  }
}
